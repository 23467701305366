import { createSlice } from '@reduxjs/toolkit'
import { gameClose, userLogin } from 'redux-store/thunk/auth.thunk'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import { getPayloadFromToken, getPrecision } from 'helpers/common.helpers'

const defaultAuthState = {
  debitWalletWaitQueue: [],
  creditWalletWaitQueue: [],
  creditDemoWalletWaitQueue: [],
  userInfo: null,
  userDetails: null,
  currencyId: null,
  currencyCode: null,
  isLoggedIn: false,
  showName: false,
  demoMode: false,
  demoWallet: { balance: 3000, currency: 'EUR' }
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    appendCreditWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditWalletWaitQueue: [...state.creditWalletWaitQueue, action.payload]
      }
    },
    updateCreditWalletWaitQueue: (state, action) => {
      if (state.demoMode) {
        return { ...state }
      }
      let _creditWalletWaitQueue = [...state.creditWalletWaitQueue]
      const creditAmt = +_creditWalletWaitQueue?.[0]
      let _userInfoWallet = { ...state.userInfo.wallet }
      if (creditAmt) {
        _userInfoWallet.balance = +getPrecision((_userInfoWallet.balance + creditAmt), 3).toFixed(2)
        _creditWalletWaitQueue = _creditWalletWaitQueue?.slice(1)
      }
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    appendCreditDemoWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditDemoWalletWaitQueue: [action.payload, ...state.creditDemoWalletWaitQueue]
      }
    },
    updateCreditDemoWalletWaitQueue: (state, action) => {
      let _creditDemoWalletWaitQueue = [...state.creditDemoWalletWaitQueue]
      const _newDemoWallet = _creditDemoWalletWaitQueue.pop() ?? state.demoWallet
      _creditDemoWalletWaitQueue = _creditDemoWalletWaitQueue?.slice(0, _creditDemoWalletWaitQueue.length - 1)
      return {
        ...state,
        creditDemoWalletWaitQueue: _creditDemoWalletWaitQueue,
        demoWallet: _newDemoWallet
      }
    },
    setShowName: (state, action) => {
      return {
        ...state,
        showName: !state.showName
      }
    },
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: action.payload
      }
    }),
    debitWalletAmt: (state, action) => {
      const updatedBalance = +getPrecision((state?.userInfo?.wallet?.balance - action.payload), 3).toFixed(2)

      return ({
        ...state,
        userInfo: {
          ...state.userInfo,
          wallet: {
            ...state.userInfo.wallet,
            balance: updatedBalance
          }
        }
      })
    },
    setDemoMode: (state, action) => {
      return {
        ...state,
        demoMode: action.payload,
        currencyCode: state.demoWallet.currency
      }
    },
    setDemoWalletCurrency: (state, action) => {
      return {
        ...state,
        currencyCode: action.payload,
        demoWallet: { ...state.demoWallet, currency: action.payload }
      }
    },
    updateDemoWalletBalance: (state, action) => {
      return {
        ...state,
        demoWallet: { ...state.demoWallet, balance: action.payload }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        const { user, wallet, accessToken, operator } = action.payload
        const payload = getPayloadFromToken(accessToken)
        const currencyId = payload?.currencyId
        const currencyCode = payload?.currencyCode
        const operatorId = operator?.id
        return {
          ...state,
          currencyId,
          operatorId,
          currencyCode,
          userInfo: { user, wallet },
          isLoggedIn: true
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          userDetails: action.payload
        }
      })
      .addCase(gameClose.fulfilled, (state, action) => {
        return defaultAuthState
      })
  }
})

export const {
  setUserInfo, appendCreditWalletWaitQueue,
  updateCreditWalletWaitQueue, setShowName,
  setDemoMode, updateDemoWalletBalance, setDemoWalletCurrency,
  appendCreditDemoWalletWaitQueue, updateCreditDemoWalletWaitQueue,
  debitWalletAmt
} = authSlice.actions

export default authSlice.reducer
