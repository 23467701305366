import * as Sentry from "@sentry/react"

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    serverName: 'user-frontend',
    dsn: "https://40ab1f94792e34cb0806337f48511216@o4508454139527168.ingest.us.sentry.io/4508529488494593",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [window.location.origin],
  })
}
