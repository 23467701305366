import React from 'react'
import './sentry/index.js'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import './translation/index'
import { Provider } from 'react-redux'
import ErrorBoundary from 'components/ui-kit/ErrorBoundary/index'
import store from 'redux-store/store'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
          style={{ zIndex: 99999 }}
        />
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

export default store
